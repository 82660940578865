import countries from "countries-list";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PublishIcon from "@mui/icons-material/Publish";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import { CircularProgress } from "@mui/material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import CachedIcon from "@mui/icons-material/Cached";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoIcon from "@mui/icons-material/Info";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import EventNoteIcon from "@mui/icons-material/EventNote";

//  Messages or Hard Coded Data
const TextMessage = {
  MONGOOSE_ID: "_id",
  ERROR_TEXT: "Error Occured.Try again",
  EMAIL_FIELD_REQUIRED: "Enter the email",
  PASSWORD_FIELD_REQUIRED: "Enter the password",
  EMAIL_NOT_VALID: "Enter Valid Email Address",
  EMAIL_EXIST: "The email already exists. Please provide a different email address.",
  Company_EXIST: "The company already exists. Please choose a different company name.",
  Card_EXIST: "The card already exists. Please select a different card name.",
  VALUE_TOO_SHORT: "Given value is too short",
  VALUE_TOO_LONG: "Given value is too long",
  INVALID_CREDENTIAL: "Invalid credential, please try again.",
  FORGET_PASSWORD_MESSAGE:
    "Confirm your registered email and we will send reset link and the instructions.",
  FORGET_PASSWORD_EMAIL_SUCCESS: "Reset Password link has been sent. Please check your mail.",
  FORGET_PASSWORD_EMAIL_Failure: "User is not registered.",
  PASSWORD_NOT_MATCHED: "Confirm passwords do not match",
  PASSWORD_RESET_SUCCESSFULLY: "Password reset successfully.",
  PASSWORD_LINK_EXPIRED: "Reset password link is expired.",
  INVALID_URL: "Please enter valid URL.",
  INVALID_SPACE: "Empty Space is not allowed",
  PASSWORD_LENGTH_VALIDATION: "Password must be between 8-16 character",
  PASSWORD_UPPERCASE_VALIDATION: "Password must contain at least one uppercase letter",
  PASSWORD_LOWERCASE_VALIDATION: "Password must contain at least one lowercase letter",
  PASSWORD_SYMBOL_VALIDATION: "Password must contain at least one symbol",
  PASSWORD_DIGIT_VALIDATION: "Password must contain at least one digit",
  STATUS_UPDATE_SUCCESS: "Status updated successfully.",
  MEMBER_ADD_SUCCESS: "Member added successfully.",
  MEMBER_UPDATE_SUCCESS: "Member updated successfully.",
  MEMBER_DELETE_SUCCESS: "Member deleted successfully.",
  PHONE_LENGTH_VALIDATION: "Phone number must be of atleast 10 numbers",
  PHONE_VALIDATION: "Invalid phone number",
  USER_ACTIVATED_SUCCESS: "User activated successfully.",
  USER_DEACTIVATED_SUCCESS: "User deactivated successfully.",
  USER_LOGOUT_TITTLE: "Logout",
  LOGOUT_MESSAGE: "Are you sure you want to logout?",
  NOTIFICATION_SUCCESS: "success",
  NOTIFICATION_ERROR: "error",
  // Mobile Users
  MOBILE_USERS_ERROR: "Error occured while fetching mobile users.",

  // Customer Login
  INVALID_PHONE_NUMBER: "Invalid phone number",

  // Redux cosntants
  IDLE: "idle",
  PENDING: "pending",
  REJECTED: "rejected",
  FULFILLED: "fullfilled",
  NODATA: "nodata",
  NO_DATA_FOUND: "No data found.",

  // Laon Form
  LOAN_FORM_SUBMIT_SUCCESS: "Loan form submitted successfully.",
  LOAN_FORM_ERROR: "Error occured while fetching loan form.",
  LOAN_FORM_SUCCESS: "Loan form fetched successfully.",
  LOAN_FORM_UPDATE_SUCCESS: "Loan form updated successfully.",
  LOAN_FORM_DELETE_SUCCESS: "Loan form deleted successfully.",
  LOAN_FORM_DELETE_ERROR: "Loan form cannot be deleted.",
  UPLOAD_SUCCESS_MESSAGE: "File uploaded successfully.",

  // Lead utils Banks
  LEAD_UTILS_BANKS_CREATE_SUCCESS: "Bank created successfully.",
  LEAD_UTILS_BANKS_UPDATE_SUCCESS: "Bank updated successfully.",
  LEAD_UTILS_BANKS_DELETE_SUCCESS: "Bank deleted successfully.",
  LEAD_UTILS_BANKS_DELETE_ERROR: "Bank cannot be deleted.",
  LEAD_UTILS_BANKS_ALREADY_EXIST: "Bank already exist.",

  // Lead utils Codes
  LEAD_UTILS_CODES_CREATE_SUCCESS: "Code created successfully.",
  LEAD_UTILS_CODES_UPDATE_SUCCESS: "Code updated successfully.",
  LEAD_UTILS_CODES_DELETE_SUCCESS: "Code deleted successfully.",
  LEAD_UTILS_CODES_DELETE_ERROR: "Code cannot be deleted.",
  LEAD_UTILS_CODES_ALREADY_EXIST: "Code already exist.",

  // Lead utils Sales Manager
  LEAD_UTILS_SALES_MANAGER_CREATE_SUCCESS: "Sales Manager created successfully.",
  LEAD_UTILS_SALES_MANAGER_UPDATE_SUCCESS: "Sales Manager updated successfully.",
  LEAD_UTILS_SALES_MANAGER_DELETE_SUCCESS: "Sales Manager deleted successfully.",
  LEAD_UTILS_SALES_MANAGER_DELETE_ERROR: "Sales Manager cannot be deleted.",
  LEAD_UTILS_SALES_MANAGER_ALREADY_EXIST: "Sales Manager already exist.",

  // Bank Opening Category
  SAVING: "Saving",
  CREDIT: "Credit",

  // Bank Name
  KOTAK: "Kotak",
  AU: "AU",
  INDUSIND: "indusind",
  REQUIRED: "Required",
};

// Page titles
export const PageTitles = {
  MOBILE_USERS: "Mobile Users",
  UTILITY_BILL_PAYMENTS: "Utility Bill Payments",
  PERSONAL_LOAN: "Personal Loan",
  CREDIT_CARD_LOAN: "Credit Cards",
  HOME_LOAN: "Home Loan",
  BUSINESS_LOAN: "Business Loan",
  AUTO_LOAN: "Auto Loan",
  EDUCATION_LOAN: "Education Loan",
  LEAD_BAY: "Lead Bay",
  LEAD_UTILS_BANKS: "Banks",
  LEAD_UTILS_CODES: "Codes",
  LEAD_UTILS_SALES_MANAGER: "Sales Manager",
  MOBILE_RECHARGE: "Mobile Recharge",
  BANK_ACCOUNT_OPENING: "Bank Account Opening",
  CIBIL_SCORE: "CIBIL Score",

  // Bill Payment Types
  ELECTRICITY: "Electricity Bill",
  WATER: "Water Bill",
  GAS: "Gas Bill",
  RECHARGE: "Recharge Bill",
  FASTAG: "Fastag",
  MUNCIPAILITY: "Municipality Bill",
};

// Button titles
export const ButtonTitles = {
  LOGIN_BUTTON: "Login",
  FORGET_PASSWORD_BUTTON: "Forgot Password",
  BACK_TO_LOGIN_BUTTON: "Back to Login",
  RESET_PASSWORD_BUTTON: "Reset Password",
  NEW_SAFE: "Safe Card",
  NEW_UNSAFE: "Unsafe Card",
  NEW_NCR: "NCR Card",
  NEW_INCIDENT: "Incident Card",
  NEW_SHIFT: "Shift Card",
  SHIFT_STATUS: "Shift Status",
  NEW_MEMBER: "Member",
  NEW_ACTIVITY: "Activity",
  NEW_CATEGORY: "Category",
  NEW_REPORT: "Report",
  NEW_DPR: "DPR",
  NEW_PROJECT: "Project",
  NEW_LOCATION: "Location",
  NEW_PROJECT_STRING: "Project String",
  NEW_ASSET: "Asset",
  NEW_FUNCTION: "Function",
  NEW_REPORT_TYPE: "Report Type",
  NEW_REPORT_PARAMETER_FIGURE: "Parameter & Figure",
  RESET_FILTER: "Reset Filter",
  EXPORT: "Export",
  LOADING: "Loading...",
  SUBMIT: "Submit",
  SUBMIT_LOADING: "Submitting...",
  UPDATE: "Update",
  UPDATE_LOADING: "Updating...",
  CREATE_DROPDOWN: "Create Dropdown Field",
  CREATE_CHECKBOX: "Create Checkbox Field",
  CREATE_SHORTANSWER: "Create Short Field",
  CREATE_DATE: "Create Date Field",
  CREATE_DATETIME: "Create Datetime Field",
  CREATE_NUMBER: "Create Number Field",
  CREATE_SLIDER: "Create Slider Field",
  CREATE_BOOLEAN: "Create Boolean Field",
  APPROVE_LICENSE: "Approve",
  REJECT_LICENSE: "Reject",
  NEW_FIELD: "Add a Field",
  STATUS: "Status",
  PUBLISH: "Publish",
  PUBLISH_LOADING: "Publishing...",
  CREATE_FIELD: "Create Field",
  CREATE_FIELD_LOADING: "Creating Field...",
  UPDATE_FIELD: "Update Field",
  UPDATE_FIELD_LOADING: "Updating Field...",
  ADD_OPTIONS: "Add Options",
  APPROVE_CERTIFICATE: "Approve",
  REJECT_CERTIFICATE: "Reject",
  NEW_ROLE: "Role",
  NEGATIVE_BUTTON: "No",
  POSITIVE_BUTTON: "Yes",
  POSITIVE_LOADING_BUTTON: "Loading...",
  SWITCH_TO_SUPERADMIN: "Switch to Super Admin",
  NEW_WAREHOUSE: "Warehouse",
  UPDATE_AND_CONTINUE: "Update and Continue",
  SAVE_AND_CONTINUE: "Save and Continue",
  ADD_PRODUCT: "Add Product",
  EDIT_DETAILS: "Edit Details",
  SAVE: "Save",
  CANCEL: "Cancel",
  BACK: "Back",
  DOWNLOAD_IMPORT_SAMPLE: "Download Import Sample",
  NEW_REQUEST: "Request",
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  PLACE_ORDER: "Place Order",
  LEAD_UTILS_BANKS: "Add Bank",
  LEAD_UTILS_CODES: "Add Code",
  LEAD_UTILS_SALES_MANAGER: "Add Sales Manager",
  BANK_OPENING: "Bank Opening",
  HOME_LOAN: "HL",
  BUSINESS_LOAN: "BL",
  PERSONAL_LOAN: "PL",
  CREDIT_CARD: "CC",
  EDUCATION_LOAN: "EL",
  CAR_LOAN: "CL",
};

// License and Permission
export const LicensePermission = {
  // License
  QHSE_MANAGEMENT: "QHSE Management",
  PROJECT_MANAGEMENT: "Project Management",
  WFM_WIZARD: "WFM Wizard",
  // Permissions
  SAFE_CARD: "Safe",
  UNSAFE_CARD: "Unsafe",
  NCR_CARD: "NCR",
  INCIDENT_CARD: "Incident",
  DAL_SHIFT_DETAILS: "DAL Shift Details",
  REPORT: "Report",
  DPR: "DPR",
  EQUIPMENT: "Equipment",
  WAREHOUSE: "Warehouse",
};

// Card Titles
export const CardTitles = {
  LOGIN: "Login",
  FORGET_PASSWORD: "Forgot Password",
  SHIFT: "Shift",
  SHIFT_STATUS: "Shift Status",
  SHIFT_OVERVIEW: "Shift Overview",
  TEAM_MEMBERS: "Team Members",
  ACTIVITY: "Activity",
  ORGANIZATION: "Organization",
  SYSTEM_MANAGEMENT: "User Management",
  PROJECT_MANAGEMENT: "Project Management",
  HEALTH_SAFETY: "Quality, Health & Safety",
  ACTIONS: "Actions",
  EQUIPMENT: "Equipment",
  PERSONAL_SETTING: "Personal Setting",
  ADD_REPORT: "Add Report",
  REPORT_DETAILS: "Report Details",
};

// color constants
export const Colors = {
  PRIMARY: "#043b9f",
  PRIMARY1: "191A51",
  SECONDARY: "#FFC107",
  ERROR: "#9D0202",
  SUCCESS: "#029E3B",
  INFO: "#2196F3",
  WARNING: "#FF9800",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  GREY: "#9E9E9E",
  STATUS_Background: "#F5F5F5",
  STATUS_COLOR: "#FFC107",
  LIGHT_YELLOW: "#f7f2e5",
  DARK_YELLOW: "#B68300",
  LIGHT_ORANGE: "#ffefe5",
  DARK_ORANGE: "#ff6600",
  LIGHT_RED: "#9d02021a",
  DARK_RED: "#9D0202",
  LIGHT_GREEN: "#2a8c001a",
  DARK_GREEN: "#2A8C00",
  LIGHT_GRAY: "#E8E8EE",
  LIGHT_GRAY2: "#D9D9D9",

  // Chart Colors
  COLLAPSE_BTN: "#FF6600",
  BLACK2: "#101828",
  LIGHT_GRAY3: "#49586D",
  LIGHT_GRAY4: "#E8ECF4",
  LIGHT_GRAY5: "#E4E6E9",
  DARK_GREY: "#475467",
  LIGHT_ORANGE2: "#FEC299",
  LIGHT_BROWN: "#E2B3B4",
  DARK_BROWN: "#9E0003",
  LIGHT_PURPLE: "#D9B8FF",
  DARK_PURPLE: "#9E00FF",
  LIGHT_TEAL: "#CDECD8",
  DARK_TEAL: "#049E3D",
  LIGHT_GREEN2: "#CDECD8",
  DARK_GREEN2: "#049E3D",
  LIGHT_YELLOW2: "#EADAB3",
  LIGHT_BLUE: "#BABBCB",
  DARK_BLUE: "#191A51",
  DISABLED: "#f0f2f5",
  GREY1: "#e2e8f0",
  PINK: "#FFCCCC",
  LIGHT_PINK: "#FBE4D5",
  DARK_PINK: "#FF9999",
  LIGHT_ORANGE1: "#FEF2CB",
  LIGHT_GREEN3: "#E2EFD9",
  DARK_RED2: "#F04438",
  DARK_ORANGE2: "#E46A11",
  LIGHT_CYAN: "#CDECD8",
  DARK_CYAN: "#049E3D",
  LIGHT_LIME: "#CDECD8",
  DARK_LIME: "#049E3D",
  LIGHT_MINT: "#CDECD8",
  DARK_MINT: "#049E3D",
  LIGHT_BLUE2: "#CDECD8",
  DARK_BLUE2: "#049E3D",
  LIGHT_GRAY6: "#D9D9D9",
  DARK_GRAY: "#9E9E9E",
};

// icon constants
export const Icons = {
  NEW: <AddCircleOutlineOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  ADD: <AddIcon fontSize="large" sx={{ cursor: "pointer", color: "#667085" }} />,
  ADD2: <AddIcon fontSize="medium" sx={{ cursor: "pointer", color: "#475467" }} />,
  EDIT: <EditOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: "#475467" }} />,
  EDIT2: <EditOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  NOTE_EDIT: <EventNoteIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  DELETE: (
    <DeleteOutlineOutlinedIcon
      color="error"
      fontSize="medium"
      sx={{ cursor: "pointer", color: "#475467" }}
    />
  ),
  DELETE2: (
    <DeleteOutlineOutlinedIcon
      fontSize="medium"
      sx={{ cursor: "pointer", zIndex: 9999, color: "red" }}
    />
  ),
  VIEW: (
    <VisibilityOutlinedIcon
      color="info"
      fontSize="medium"
      sx={{ cursor: "pointer", color: "#475467" }}
    />
  ),
  VIEW2: (
    <VisibilityOutlinedIcon
      color="info"
      fontSize="medium"
      sx={{ cursor: "pointer", color: "#ffffff" }}
    />
  ),
  RESET_FILTER: <RestartAltOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  EXPORT: <FileUploadOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  CLOSE: <CloseOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  DROPDOWN: () => <KeyboardArrowDownIcon fontSize="medium" sx={{ color: "#475467" }} />,
  PUBLISH: <PublishIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  DOWNLOAD: <FileDownloadOutlinedIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  ACCEPT: <CheckIcon sx={{ cursor: "pointer" }} />,
  ACCEPT2: <CheckCircleOutlineIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  REJECT: <ClearIcon fontSize="large" sx={{ cursor: "pointer", color: "#ffffff" }} />,
  WARNING: (
    <WarningAmberOutlinedIcon fontSize="small" sx={{ cursor: "pointer", color: "#EB8D00" }} />
  ),
  CROSS: <CancelOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: "#ffffff" }} />,
  CROSS2: <CancelOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: "#FF2E2E" }} />,
  CROSS3: <CloseIcon sx={{ color: "#9D0202" }} />,
  SEACRH: <SearchIcon fontSize="medium" sx={{ cursor: "pointer", color: "#667085" }} />,
  LOADING: <CircularProgress size={20} sx={{ color: Colors.PRIMARY }} />,
  LOADING2: <CircularProgress sx={{ color: Colors.PRIMARY }} />,
  NEXT: (
    <ArrowCircleRightOutlinedIcon fontSize="large" sx={{ cursor: "pointer", color: "#ffffff" }} />
  ),
  PREVIOUS: (
    <ArrowCircleLeftOutlinedIcon fontSize="large" sx={{ cursor: "pointer", color: "#ffffff" }} />
  ),
  DASH: <RemoveIcon sx={{ cursor: "pointer", color: "#ff6600" }} />,
  ADMIN: (
    <AdminPanelSettingsOutlinedIcon
      fontSize="medium"
      sx={{ cursor: "pointer", color: "#475467" }}
    />
  ),
  DOWNLOAD2: <DownloadIcon fontSize="medium" sx={{ cursor: "pointer", color: "#ffffff" }} />,
  PRINT: <PrintIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  QR: <QrCode2Icon fontSize="medium" sx={{ cursor: "pointer", color: "#667085" }} />,
  RELOAD: <CachedIcon fontSize="large" sx={{ cursor: "pointer", color: "#000000" }} />,
  MANAGE_ACCOUNT: (
    <ManageAccountsOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: "#475467" }} />
  ),
  IMAGE: <ImageOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: "#667085" }} />,
  ARROW_DOWN: <KeyboardArrowDownIcon fontSize="medium" sx={{ color: "#B68300" }} />,
  REJECTED: (
    <CancelOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: Colors.DARK_RED }} />
  ),
  APPROVED: (
    <CheckCircleOutlinedIcon fontSize="medium" sx={{ cursor: "pointer", color: Colors.SUCCESS }} />
  ),
  CHEKOUT: <ShoppingCartCheckoutIcon fontSize="medium" sx={{ cursor: "pointer" }} />,
  ATTACHMENT: <AttachFileIcon fontSize="small" sx={{ cursor: "pointer" }} />,
  INFO: <InfoIcon fontSize="small" sx={{ cursor: "pointer" }} />,
  NOTIFICATION: (
    <NotificationsNoneIcon fontSize="medium" sx={{ cursor: "pointer", color: "#475467" }} />
  ),
};

// custom jsx components
export const CustomComponents = {
  DROPDOWN_ICON: () => <KeyboardArrowDownIcon fontSize="medium" sx={{ color: "#475467" }} />,
};

// Modal Content
export const ModalContent = {
  // Lead Utils Banks
  BANK_STATUS_TITLE: "Bank status",
  BANK_ACTIVE_STATUS_MESSAGE: "Are you sure you want to activate this bank?",
  BANK_INACTIVE_STATUS_MESSAGE: "Are you sure you want to deactivate this bank?",
  CREATE_BANK_TITLE: "Create Bank",
  UPDATE_BANK_TITLE: "Update Bank",
  PROFILE_FUNCTION_DELETE_TITLE: "Delete Function",
  PROFILE_FUNCTION_DELETE_MESSAGE: "Are you sure you want to delete this function?",

  // Lead Utils Codes
  CODE_STATUS_TITLE: "Code status",
  CODE_ACTIVE_STATUS_MESSAGE: "Are you sure you want to activate this code?",
  CODE_INACTIVE_STATUS_MESSAGE: "Are you sure you want to deactivate this code?",
  CREATE_CODE_TITLE: "Create Code",
  UPDATE_CODE_TITLE: "Update Code",
  DELETE_CODE_TITLE: "Delete Code",
  DELETE_CODE_MESSAGE: "Are you sure you want to delete this code?",

  // Lead Utils Sales Manager
  SALESMANAGER_STATUS_TITLE: "Sales Manager status",
  SALESMANAGER_ACTIVE_STATUS_MESSAGE: "Are you sure you want to activate this sales manager?",
  SALESMANAGER_INACTIVE_STATUS_MESSAGE: "Are you sure you want to deactivate this sales manager?",
  CREATE_SALESMANAGER_TITLE: "Create Sales Manager",
  UPDATE_SALESMANAGER_TITLE: "Update Sales Manager",
  DELETE_SALESMANAGER_TITLE: "Delete Sales Manager",
  DELETE_SALESMANAGER_MESSAGE: "Are you sure you want to delete this sales manager?",

  // Lead Utils
  EDIT_LEAD_DATA: "Edit Lead Bay Data",

  // Bank Opening
  NEW_BANK_ACCOUNT: "New Bank Account",
  EDIT_BANK_ACCOUNT: "Edit Bank Account",
  BANK_ACCOUNT_DELETE_TITLE: "Delete Bank Account",
  BANK_ACCOUNT_DELETE_MESSAGE: "Are you sure you want to delete this bank account?",

  // Mail on update of lead
  MAIL_ON_UPDATE_TITLE: "Send Mail",
  MAIL_ON_UPDATE_MESSAGE: "Are you sure you want to send mail on update of lead?",
  MAIL_ON_UPDATE_SUCCESS_MESSAGE: "Lead Updated and Mail sent successfully.",
};

// Form Fields types
export const FormFields = {
  DROPDOWN_LABEL: "Dropdown",
  DROPDOWN_VALUE: "options",
  SHORT_ANSWER_LABEL: "Short Answer",
  SHORT_ANSWER_VALUE: "text",
  CHECKBOX_LABEL: "Checkbox",
  CHECKBOX_VALUE: "checkbox",
  DATE_LABEL: "Date",
  DATE_VALUE: "date",
  DATE_TIME_LABEL: "Date & Time",
  DATE_TIME_VALUE: "datetime",
  NUMBER_LABEL: "Number",
  NUMBER_VALUE: "number",
  SLIDER_LABEL: "Slider",
  SLIDER_VALUE: "slider",
  BOOLEAN_LABEL: "Boolean",
  BOOLEAN_VALUE: "boolean",
  IMAGE_LABEL: "Image",
  IMAGE_VALUE: "image",
  SIGNATURE_LABEL: "Signature",
  SIGNATURE_VALUE: "signature",
  NOT_APPLICABLE: "N/A",
};

//  feature tags
export const FeatureTags = {
  LOAN_FORM: "Loan Form",
};

export const defaultData = {
  DEFAULT_DATA_IDENTIFIER: "default",
  NORMAL_DATA_IDENTIFIER: "normal",
  DATE_ON_SINGLE_API_CALL: 50,
  PER_PAGE: 25,
  DATE_ON_SINGLE_API_CALL_2: 10,
  PER_PAGE_2: 5,
  PAGE: 0,
  WEB_DATE_FORMAT: "DD-MM-YYYY",
  DATABSE_DATE_FORMAT: "YYYY-MM-DD",
  WEB_12_FORMAT: "DD-MM-YYYY HH:mm A",
  WEB_24_HOURS_FORMAT: "DD-MM-YYYY HH:mm:ss",
  DATABASE_24_HOURS_FORMAT: "YYYY-MM-DDTHH:mm:ss",
  REACTDATETIMEPICKER_DATE_FORMAT: "dd-MM-yyyy",
  REACTDATETIMEPICKER_12_HOURS_FORMAT: "dd-MM-yyyy HH:mm aa",
  REACTDATETIMEPICKER_24_HOURS_FORMAT: "dd-MM-yyyy HH:mm:ss",
  UNAUTHORIZED_ROLE: "unauthorized",
  ADMIN_ROLE: "admin",
  SUPER_ADMIN_ROLE: "superadmin",
  WEB_ACCESSTYPE: "web",
  BOTH_ACCESSTYPE: "both",
  MOBILE_ACCESSTYPE: "mobile",
  PENDING_STATUS: "pending",
  FULFILLED_STATUS: "fulfilled",
  REJECTED_STATUS: "rejected",

  // Config Screens Id
  SAFE_CARD_SCREEN_ID: "safe_01",
  UNSAFE_CARD_SCREEN_ID: "unsafe_02",
  NCR_CARD_SCREEN_ID: "ncr_03",
  INCIDENT_CARD_SCREEN_ID: "incident_04",
  FEEDBACK_SCREEN_ID: "submit_feedback_05",
  SHIFT_SCREEN_ID: "open_shift_06",
  REPORT_SCREEN_ID: "report_07",
  SHIFT_ACTIVITY_SCREEN_ID: "shift_activity_08",
  EQUIPMENT_SCREEN_ID: "equipment_10",
  WAREHOUSE_SCREEN_ID: "warehouse_11",

  HOME_LOAN_SCREEN_ID: "homeLoan_01",
  PERSONAL_LOAN_SCREEN_ID: "personalLoan_02",
  BUSINESS_LOAN_SCREEN_ID: "businessLoan_04",
  CAR_LOAN_SCREEN_ID: "carLoan_03",
  CREDIT_CARD_SCREEN_ID: "creditCard_05",
  EDUCATION_LOAN_SCREEN_ID: "educationLoan_06",
};

export const countryList = Object.values(countries.countries).map((country) => country.name);

export default TextMessage;
