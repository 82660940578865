/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from "react";

// MUI Components
import { Divider } from "@mui/material";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import LeadBayChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart/LeadBayChart";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart/index";
import FilterDropdown from "components/Dropdown/FilterDropdown";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Constant
import Constants from "utils/Constants";

// Redux Component
import { getLeadByChartsThunk } from "redux/Thunks/LoanFormConfig";

// images
import Slider1 from "assets/images/slider1.png";
import Slider2 from "assets/images/slider2.png";
import Slider3 from "assets/images/slider3.png";
import MDBox from "components/MDBox";
import { useDispatch } from "react-redux";
import { paramCreater } from "utils/methods/methods";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1500,
};

function QhseManagement() {
  const [chartData, setChartData] = useState({
    loading: true,
    data: null,
  });
  const [disbursementChart, setDisbursementChart] = useState({
    loading: true,
    label: [],
    data: [],
  });
  const [financialMonth, setFinancialMonth] = useState([
    {
      inputLabel: "Financial Year",
      list: [
        { [Constants.MONGOOSE_ID]: "2024", title: "2024-2025" },
        { [Constants.MONGOOSE_ID]: "2023", title: "2023-2024" },
        { [Constants.MONGOOSE_ID]: "2022", title: "2022-2023" },
        { [Constants.MONGOOSE_ID]: "2021", title: "2021-2022" },
        { [Constants.MONGOOSE_ID]: "2020", title: "2020-2021" },
        { [Constants.MONGOOSE_ID]: "2019", title: "2019-2020" },
        { [Constants.MONGOOSE_ID]: "2018", title: "2018-2019" },
        { [Constants.MONGOOSE_ID]: "2017", title: "2017-2018" },
        { [Constants.MONGOOSE_ID]: "2016", title: "2016-2017" },
        { [Constants.MONGOOSE_ID]: "2015", title: "2015-2016" },
        { [Constants.MONGOOSE_ID]: "2014", title: "2014-2015" },
        { [Constants.MONGOOSE_ID]: "2013", title: "2013-2014" },
        { [Constants.MONGOOSE_ID]: "2012", title: "2012-2013" },
        { [Constants.MONGOOSE_ID]: "2011", title: "2011-2012" },
        { [Constants.MONGOOSE_ID]: "2010", title: "2010-2011" },
        { [Constants.MONGOOSE_ID]: "2009", title: "2009-2010" },
      ],

      selectedValue: new Date().getFullYear().toString(),
    },
  ]);

  const dispatch = useDispatch();

  const handleFetch = async (temp = financialMonth) => {
    const param = {
      year: temp[0].selectedValue,
    };
    const { payload } = await dispatch(getLeadByChartsThunk(paramCreater(param)));
    if (payload.status === 200) {
      setChartData({
        loading: false,
        data: payload.data.data,
      });
      const tempLabels = [];
      const tempData = [];
      payload.data.data[0].disbursementData.forEach((item) => {
        tempLabels.push(`${item.month} ${item.year}`);
        tempData.push(item.count);
      });
      setDisbursementChart({
        loading: false,
        label: tempLabels,
        data: tempData,
      });
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  const handleFilterChange = (e) => {
    const temp = [...financialMonth];
    const { value, name } = e.target;
    const index = financialMonth.findIndex((filter) => filter.inputLabel === name);
    temp[index].selectedValue = value;
    setFinancialMonth(temp);
    handleFetch(temp);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Divider sx={{ marginTop: 2 }} />

      {/* Set the slider's height to 300px */}
      <MDBox sx={{ height: "300px", overflow: "hidden" }}>
        <Slider {...settings}>
          <div>
            <img
              src={Slider1}
              alt="Slide 1"
              style={{ width: "100%", height: "300px", objectFit: "fill" }} // Ensuring the image covers the box
            />
          </div>
          <div>
            <img
              src={Slider2}
              alt="Slide 2"
              style={{ width: "100%", height: "300px", objectFit: "fill" }}
            />
          </div>
          <div>
            <img
              src={Slider3}
              alt="Slide 3"
              style={{ width: "100%", height: "300px", objectFit: "fill" }}
            />
          </div>
        </Slider>
      </MDBox>

      {/* Additional content below the slider */}
      <MDBox sx={{ marginTop: "20px" }}>
        {" "}
        {/* Adjust margin as needed */}
        <MDBox sx={{ marginBottom: "50px" }}>
          <FilterDropdown
            label={financialMonth[0].inputLabel}
            name={financialMonth[0].inputLabel}
            defaultValue={financialMonth[0]?.selectedValue}
            value={financialMonth[0]?.selectedValue}
            handleChange={handleFilterChange}
            menu={financialMonth[0].list}
            key={financialMonth[0].inputLabel}
          />
        </MDBox>
        <ReportsBarChart
          color="info"
          title="Disbursement Views"
          description=""
          date="2024"
          chart={{
            labels: disbursementChart.label,
            datasets: {
              label: "Disbursement",
              data: disbursementChart.data,
            },
          }}
        />
      </MDBox>

      <LeadBayChart chartData={chartData} />
    </DashboardLayout>
  );
}

export default QhseManagement;
