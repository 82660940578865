// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import PageLayout from "examples/LayoutContainers/PageLayout";
import { Colors } from "utils/Constants";

function BasicLayout({ image, children }) {
  return (
    <PageLayout>
      <Grid container justifyContent="center" alignItems="center" height="100%">
        <Grid item xs={0} sm={0} md={0} lg={7} xl={7}>
          <MDBox
            minHeight="100vh"
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                image &&
                `${linearGradient(
                  rgba(gradients.dark.main, 0),
                  rgba(gradients.dark.state, 0)
                )}, url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
          <MDBox width="100%" height="100vh" sx={{ backgroundColor: Colors.PRIMARY, px: 10 }}>
            {children}
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
